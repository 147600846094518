<template>
  <fragment>
    <app-header v-if="!providerId" title="Dashboard" />

    <div class="container" :class="{ 'mt-4': providerId }">
      <!-- widgets -->
      <div class="row justify-content-center">

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Course Views</h3>
              <p class="lead strong">{{ data.courses_viewed }}</p>
              <p class="text-muted">Last 30 Days</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Book Now Exits</h3>
              <div class="row gx-2">
                <div class="col d-flex align-items-end">
                  <div>
                    <p class="lead strong">{{ data.book_now_exits_count }}</p>
                    <p class="text-muted">Last 30 Days</p>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-end">
                  <div class="sparkline sparkline-column">
                    <ul>
                      <li
                        v-for="(day, index) in data.book_now_exits"
                        :key="index"
                        :style="'height:' + (day >= 10 ? 10 : day) * 20 + '%'"
                        data-bs-toggle="tooltip"
                        :title="day"
                      >
                        <span class="visually-hidden">{{ day }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <div class="row gx-2">
                <div class="col">
                  <h3 class="widget-title">Course Summary</h3>
                </div>
              </div>
              <div class="row gx-2">
                <div class="col">
                  <p class="lead strong">{{ data.courses_published }}</p>
                  <p class="text-muted">Published</p>
                </div>
                <div class="col">
                  <p class="lead strong">{{ data.courses_draft }}</p>
                  <p class="text-muted">Draft</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Course Impressions</h3>
              <div class="row gx-2">
                <div class="col d-flex align-items-end">
                  <div>
                    <p class="lead strong">{{ data.course_searched_impressions_count }}</p>
                    <p class="text-muted">Last 30 Days</p>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-end">
                  <div class="sparkline sparkline-column">
                    <ul>
                      <li
                        v-for="(day, index) in data.course_searched_impressions"
                        :key="index"
                        :style="'height:' + (day >= 10 ? 10 : day) * 20 + '%'"
                        data-bs-toggle="tooltip"
                        :title="day"
                      >
                        <span class="visually-hidden">{{ day }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- recent activity -->
      <h2 class="h3 mt-4" v-if="data.recently_updated_courses && data.recently_updated_courses.length">Recent Activity</h2>
      <ul class="results-list" v-if="data.recently_updated_courses && data.recently_updated_courses.length">
        <li v-for="item in data.recently_updated_courses" :key="item.id">
          <course-card :course="item" />
        </li>
      </ul>
    </div>
    <slot name="footer" />
    <portal to="header">
      <add-course-button/>
    </portal>
  </fragment>
</template>

<script>
import CourseCard from '@/components/courses/provider/CourseCard.vue'
import AddCourseButton from '@/components/courses/AddCourseButton.vue'
import { http } from '@/utils'

export default {
  components: {
    CourseCard,
    AddCourseButton,
  },

  props: {
    providerId: {
      type: Number,
      required: false,
      default: null
    }
  },

  data () {
    return {
      data: []
    }
  },

  async created () {
    let url = 'dashboard/provider'
    if (this.providerId != null) {
      url = url + '?id=' + this.providerId
    }
    const response = await http.get(url)
    this.data = response.data.data
  },
}
</script>
