<template>
  <fragment>
    <app-header title="Dashboard" />

    <div class="container">
      <!-- invite colleague -->
      <div class="invite-colleague">
        <div class="row">
          <div class="col-lg">
            <h2>Invite a colleague</h2>
            <p>Inviting a colleague to Med CPD helps support our goals to make the task of finding CPD courses easier for the medical community. By inviting a colleague they will be sent an email so they can learn about Med CPD and register for an account.</p>
          </div>
          <div class="col-lg-auto mt-3 mt-lg-0 d-flex align-items-center">
            <button type="button" v-c-modal:inviteColleague class="btn btn-lg btn-light text-primary">
              Invite Colleague
            </button>
          </div>
        </div>
      </div>
      <!-- recently bookmarked courses -->
      <div class="dashboard-section" v-if="data.recently_bookmarked_courses && data.recently_bookmarked_courses.length">
        <h2>Recently Bookmarked</h2>
        <div class="row mb-4">
          <div class="col-md-6 col-xl-4 d-flex" v-for="item in data.recently_bookmarked_courses" :key="item.id">
            <course-card stacked :course="item">
              <template #bookmark>
                <bookmark-button v-bind="{ item }" v-c-modal:addLearningPlanRecentlyBookmarked="{ item }"/>
              </template>
            </course-card>
          </div>
        </div>
      </div>
      <!-- newly added courses -->
      <div class="dashboard-section" v-if="data.recently_created_courses && data.recently_created_courses.length">
        <h2>Newly Added Courses</h2>
        <div class="row mb-4">
          <div class="col-md-6 col-xl-4 d-flex" v-for="item in data.recently_created_courses" :key="item.id">
            <course-card stacked :course="item">
              <template #bookmark>
                <bookmark-button v-bind="{ item }" v-c-modal:addLearningPlanNewlyAdded="{ item }"/>
              </template>
            </course-card>
          </div>
        </div>
      </div>
      <!-- recently followed learning plans -->
      <div class="dashboard-section" v-if="data.recently_followed_learning_plans && data.recently_followed_learning_plans.length">
        <h2>Recently Followed Learning Plans</h2>
        <div class="row">
          <div class="col-md-6 col-xl-4 d-flex" v-for="item in data.recently_followed_learning_plans" :key="item.id">
            <learning-plan-card :learningPlan="item" hideCounts />
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
    <invite-colleague-modal ref="inviteColleague"/>
    <add-learning-plan-modal
      ref="addLearningPlanRecentlyBookmarked"
      :courses="data.recently_bookmarked_courses"
      @updated="refresh"
      @close="refresh"
    />
    <add-learning-plan-modal
      ref="addLearningPlanNewlyAdded"
      :courses="data.recently_created_courses"
      @updated="refresh"
      @close="refresh"
    />
  </fragment>
</template>

<script>
import InviteColleagueModal from '@/components/users/InviteColleagueModal.vue'
import CourseCard from '@/components/courses/user/CourseCard.vue'
import LearningPlanCard from '@/components/learning-plans/LearningPlanCard.vue'
import { http } from '@/utils'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'

export default {
  components: {
    InviteColleagueModal,
    CourseCard,
    LearningPlanCard,
    AddLearningPlanModal,
    BookmarkButton
  },

  data () {
    return {
      data: [],
    }
  },

  async created () {
    const response = await http.get('dashboard/user')
    this.data = response.data.data
  },

  methods: {
    async refresh () {
      const response = await http.get('dashboard/user')
      this.data = response.data.data
    },
  },
}
</script>
