import Provider from './DashboardProvider.vue'
import Admin from './DashboardAdmin.vue'
import User from './DashboardUser.vue'

const components = {
  provider: Provider,
  admin: Admin,
  user: User,
}

export default {
  functional: true,

  render (h, context) {
    const { role } = context.parent.$store.state.auth.user

    return h(components[role.name], context.data, context.children)
  }
}
