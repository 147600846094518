<template>
  <fragment v-if="items && items.length">
    <div class="row mt-5">
      <div class="col d-flex align-items-center">
        <h2 class="mb-0">Pending Review</h2>
      </div>
      <div class="col-auto">
        <router-link :to="{ name: 'courses', query: { status: 'pending' } }" class="btn btn-md btn-outline-primary">
          View All
        </router-link>
      </div>
    </div>
    <!-- list -->
    <ul class="results-list">
      <list-item v-for="item in items" :key="item.id" :course="item" />
    </ul>
  </fragment>
</template>

<script>
import ListItem from '@/components/courses/admin/CourseCard.vue'
import { http } from '@/utils'

export default {
  components: {
    ListItem,
  },

  data () {
    return {
      items: null,
    }
  },

  async created () {
    const response = await http.get('courses/pending-review')
    this.items = response.data.data
  },
}
</script>
