<template>
  <div class="card mb-4">
    <div class="card-body">

      <h2>Subscription Metrics</h2>

      <div class="row gx-5">
        <div class="col-lg-6 col-xl-4">

          <h2 class="h4">Subscribers</h2>
          <p>
            <span class="small text-muted">Active</span><br>
            <span v-if="data" class="text-lg">{{ data.active_subscription_count }}</span>
          </p>
        </div>
        <div class="col-lg-6 col-xl-4">
          <h2 class="h4">Invoices Issued</h2>
          <div class="row">
              <div class="col">
                <p>
                  <span class="small text-muted">This Month</span><br>
                  <span v-if="data" class="text-lg">{{ data.invoices_count }} | {{ invoicesAmount }}</span>
                </p>
              </div>
              <div class="col-auto">
                <p class="text-end">
                  <span class="small text-muted">Awaiting Payment</span><br>
                  <span class="text-lg">{{ invoicesOutstanding }}</span>
                </p>
              </div>
          </div><!-- /.row -->

        </div>
        <div class="col-lg-6 col-xl-4">
          <h2 class="h4">Recurring Revenue</h2>
          <div class="row">
            <div class="col">
              <p>
                <span class="small text-muted">Monthly</span><br>
                <span class="text-lg">{{ subscriptionsIncomeMonthly }}</span>
              </p>
            </div>
            <div class="col-auto">
              <p class="text-end">
                <span class="small text-muted">Annual</span><br>
                <span class="text-lg">{{ subscriptionsIncomeYearly }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils'

export default {
  data () {
    return {
      data: null,
    }
  },

  async created () {
    const response = await http.get('dashboard/admin-subscriptions')
    this.data = response.data.data
  },

  computed: {
    invoicesAmount () {
      if (!this.data) {
        return null
      }

      return this.formatPrice(this.data.invoices_amount)
    },

    invoicesOutstanding () {
      if (!this.data) {
        return null
      }

      return this.formatPrice(this.data.invoices_outstanding)
    },

    subscriptionsIncomeMonthly () {
      if (!this.data) {
        return null
      }

      return this.formatPrice(this.data.subscriptions_income_monthly)
    },

    subscriptionsIncomeYearly () {
      if (!this.data) {
        return null
      }

      return this.formatPrice(this.data.subscriptions_income_yearly)
    },
  },

  methods: {
    formatPrice (price) {
      return (price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
  },
}
</script>
