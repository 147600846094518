<template>
  <fragment>
    <app-header title="Dashboard" />

    <div class="container">
      <!-- widgets -->
      <div class="row">

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">New User Registrations</h3>
              <p class="lead strong">{{ data.new_user_registrations }}</p>
              <p class="text-muted">Last 30 Days</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Book Now Exits</h3>
              <div class="row gx-2">
                <div class="col d-flex align-items-end">
                  <div>
                    <p class="lead strong">{{ data.book_now_exits_count }}</p>
                    <p class="text-muted">Last 30 Days</p>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-end">
                  <div class="sparkline sparkline-column">
                    <ul>
                      <li
                        v-for="(day, index) in data.book_now_exits"
                        :key="index"
                        :style="'height:' + (day >= 10 ? 10 : day) * 20 + '%'"
                        data-bs-toggle="tooltip"
                        :title="day"
                      >
                        <span class="visually-hidden">{{ day }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <div class="row gx-2">
                <div class="col">
                  <h3 class="widget-title">User Actions</h3>
                </div>
                <div class="col-auto text-muted">
                    Last 30 Days
                </div>
              </div>
              <div class="row gx-2">
                <div class="col">
                  <p class="lead strong">{{ data.articles_shared }}</p>
                  <p class="text-muted">Article Shares</p>
                </div>
                <div class="col">
                  <p class="lead strong">{{ data.user_invites_sent }}</p>
                  <p class="text-muted">User Invites Sent</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Course Views</h3>
              <div class="row gx-2">
                <div class="col d-flex align-items-end">
                  <div>
                    <p class="lead strong">{{ data.courses_viewed_count }}</p>
                    <p class="text-muted">Last 30 Days</p>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-end">
                  <div class="sparkline sparkline-column">
                    <ul>
                      <li
                        v-for="(day, index) in data.courses_viewed"
                        :key="index"
                        :style="'height:' + (day >= 10 ? 10 : day) * 20 + '%'"
                        data-bs-toggle="tooltip"
                        :title="day"
                      >
                        <span class="visually-hidden">{{ day }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <div class="row gx-2">
                <div class="col">
                  <h3 class="widget-title">Active Users</h3>
                </div>
                <div class="col-auto text-muted">
                    Last 30 Days
                </div>
              </div>
              <div class="row gx-2">
                <div class="col">
                  <p class="lead strong">{{ data.total_logins }}</p>
                  <p class="text-muted">Total Logins</p>
                </div>
                <div class="col">
                  <p class="lead strong">{{ data.total_unique_logins }}</p>
                  <p class="text-muted">Total Unique Logins</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h3 class="widget-title">Course Impressions</h3>
              <div class="row gx-2">
                <div class="col d-flex align-items-end">
                  <div>
                    <p class="lead strong">{{ data.course_searched_impressions_count }}</p>
                    <p class="text-muted">Last 30 Days</p>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-end">
                  <div class="sparkline sparkline-column">
                    <ul>
                      <li
                        v-for="(day, index) in data.course_searched_impressions"
                        :key="index"
                        :style="'height:' + (day >= 10 ? 10 : day) * 20 + '%'"
                        data-bs-toggle="tooltip"
                        :title="day"
                      >
                        <span class="visually-hidden">{{ day }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <subscription-metrics-card />

      <!-- user accounts -->
      <div class="card mb-4">
        <div class="card-body">
          <h2>User Accounts</h2>
          <user-accounts-graph v-if="data.ten_month_user_counts" :data="data.ten_month_user_counts" />
        </div>
      </div>

      <!-- courses published -->
      <div class="card mb-4">
        <div class="card-body">
          <h2>Courses Published</h2>
          <courses-published-graph v-if="data.ten_week_courses_published" :data="data.ten_week_courses_published" />
        </div>
      </div>

      <!-- active course -->
      <div class="card mb-4">
        <div class="card-body">
          <h2>Active Course</h2>
          <courses-active-graph v-if="data.ten_month_courses_active" :data="data.ten_month_courses_active" />
        </div>
      </div>

      <courses-pending-review />
    </div>
    <slot name="footer" />
  </fragment>
</template>

<script>
import SubscriptionMetricsCard from '@/components/dashboard/admin/SubscriptionMetricsCard'
import CoursesPendingReview from '@/views/courses/admin/CoursesListPendingReview'
import UserAccountsGraph from '@/components/dashboard/admin/UserAccountsGraph'
import CoursesPublishedGraph from '@/components/dashboard/admin/CoursesPublishedGraph'
import CoursesActiveGraph from '@/components/dashboard/admin/CoursesActiveGraph'
import { http } from '@/utils'

export default {
  components: {
    SubscriptionMetricsCard,
    CoursesPendingReview,
    UserAccountsGraph,
    CoursesPublishedGraph,
    CoursesActiveGraph
  },

  data () {
    return {
      data: []
    }
  },

  async created () {
    const response = await http.get('dashboard/admin')
    this.data = response.data.data
  }
}
</script>
