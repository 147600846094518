<template>
  <div
    id="chart-courses-active"
    class="c3"
    style="max-height: 280px; position: relative"
  ></div>
</template>

<script>
import c3 from 'c3'

export default {
  props: {
    data: Object,
    height: {
      type: Number,
      default: 280,
    },
  },

  data () {
    return {
      chart: null,
      empty: false,
    }
  },

  async mounted () {
    if (Object.values(this.data) && Object.values(this.data).length === 0) {
      this.empty = true
    } else {
      this.empty = false
    }

    setTimeout(this.draw, 0)
  },

  methods: {
    draw () {
      if (this.chart) {
        this.chart.destroy()
      }

      this.chart = c3.generate({
        bindto: this.$el,
        size: {
          height: this.height,
        },
        legend: {
          show: false,
        },
        padding: {
          top: 20,
          bottom: 20,
        },
        grid: {
          x: {
            show: false,
          },
          y: {
            show: true,
          },
        },
        data: {
          empty: {
            label: {
              text: this.empty ? 'Not Enough Data' : null,
            },
          },
          type: 'area',
          columns: this.empty ? [] : [['data1', ...Object.values(this.data)]],
          names: {
            data1: 'Courses',
          },
          colors: {
            data1: '#1DA1F2',
          },
        },
        axis: {
          x: {
            type: 'category',
            categories: Object.keys(this.data),
            tick: {
              culling: false,
            },
          },
        },
        oninit: function () {
          this.svg._groups[0][0].getElementsByTagName('defs')[0].innerHTML +=
              '<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1"><stop stop-color="#1DA1F2" stop-opacity="1" offset="0%"></stop><stop stop-color="#1DA1F2" stop-opacity="0" offset="100%"></stop></linearGradient>'
        },
      })
    },
  },
}
</script>
