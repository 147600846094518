<template>
  <div class="modal modal-bg-brand" id="modal-thing" tabindex="-1" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-brand">
        <!-- header -->
        <div class="modal-header">
          <h1 class="modal-title">Invite a Colleague</h1>
          <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
        </div>

        <!-- body -->
        <validation-observer tag="div" ref="validation" class="modal-body" >
          <p>Help build the Med CPD community and support the health of your colleague's career in health.</p>
          <div class="row mt-4">
            <validation-provider tag="div" name="email" rules="required|email" class="col" >
              <label class="visually-hidden">Email Address</label>
              <c-input type="email" v-model="email" placeholder="Email Address" />
            </validation-provider>
            <div class="col-auto">
              <c-button variant="primary" @click="submit" validate>
                Send
              </c-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { toast, CModal } from '@chameleon/chameleon-vue'
import { http } from '@/utils'

export default {
  extends: CModal,

  data () {
    return {
      email: null
    }
  },

  methods: {
    async submit () {
      const { email } = this

      await http.post('invite', { email })

      toast('Email invite sent successfully.', 'success')

      this.close()
    }
  }
}
</script>
